/* import the necessary Bootstrap files */

// overwrite theme variables
$primary: #7B001E;
// $danger: #7B001E;
$secondary: #CC9865;

.theme-navbar {
    background-color: #7B001E;
    color: #fff;

    .nav-link {
        color: #fff !important;
    }

    .nav-item {
        border-bottom: solid 3px #CC9865;
    }

    .nav-item:hover {
        color: #fff;
        text-decoration: underline;
        background-color: #CC9865;
        border-radius: 2px;
    }
}

.theme-footer {
    background-color: #7B001E;
    color: #fff;

    a {
        color: #fff;
    }

    a:hover {
        color: #CC9865
    }
}

.brand-logo {
    height: auto;
    width: 190px;
}

/* finally, import Bootstrap */
@import "~bootstrap/scss/bootstrap.scss"